import React from 'react';
import './App.css';

function App() {
  return (
    <div>
      <link href="https://unpkg.com/nes.css@latest/css/nes.min.css" rel="stylesheet" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap" rel="stylesheet" />
      <header className="App-header">
        {/* <img src="https://avatars.githubusercontent.com/u/5160789" alt="logo" /> */}
        <img class="hero" src="/goalie.png" alt="logo" />

        {/*
        <div class="nes-container with-title is-centered">
          <p class="title">Current Status</p>
          <p>Good morning. Thou hast had a good night's sleep, I hope.</p>
        </div>
        */}

        
        <div>
          <h1>Links</h1>
          <a
            className="profile-link"
            href="https://twitter.com/jusbus_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="nes-icon twitter is-medium" />
            <span>
              Twitter
            </span>
          </a>
          <a
            className="profile-link"
            href="https://www.linkedin.com/in/justinlec/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="nes-icon linkedin is-medium" />
            <span>
              LinkedIn
            </span>
          </a>
          <a
            className="profile-link"
            href="https://github.com/justinlecc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="nes-icon github is-medium" />
            <span>
              Github
            </span>
          </a>
          <a
            className="profile-link"
            href="https://www.eliteprospects.com/player/11672/justin-leclerc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="nes-icon trophy is-medium" />
            <span>
              Hockey Stats
            </span>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
